<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th>Src Warehouse Area</vs-th>
                <vs-th>Dest Warehouse Area</vs-th>
                <vs-th>Notes</vs-th>
                <vs-th>Sku Code</vs-th>
                <vs-th>Batch</vs-th>
                <vs-th>Quantity</vs-th>
                <vs-th>Unit</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Remark</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        Code : <b>{{ tr.data.src_warehouse_area }}</b>
                    </vs-td>
                    <vs-td>
                        Code : <b>{{ tr.data.dest_warehouse_area }}</b>
                    </vs-td>
                    <vs-td>{{ tr.data.notes }}</vs-td>
                    <vs-td>{{ tr.data.sku_code }}</vs-td>
                    <vs-td>{{ tr.data.batch }}</vs-td>
                    <vs-td>{{ tr.data.qty }}</vs-td>
                    <vs-td>{{ tr.data.unit }}</vs-td>
                    <vs-td>{{ tr.status }}</vs-td>
                    <vs-td>{{ tr.remark }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {
        // ...
    },
    props: {
        logImportID: {
            type: Number,
        },
        draw: {
            type: Number,
        },
        warehouse: {
            type: Array,
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            table: this.tableDefaultState(),
        }
    },
    mounted() {
        // ...
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            let warehouseIDs = [];
            this.warehouse.forEach(val => {
                warehouseIDs.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                log_import_id: this.logImportID,
                warehouse_id: warehouseIDs,
            }

            this.$vs.loading();
            this.$http.post("api/wms/v1/inter-storage/log-import-line", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    console.log(resp.data.records)
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    console.log(resp.message);
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
    },
    watch: {
        logImportID() {
            this.getData();
        },
        draw() {
            if (this.logImportID > 0) {
                this.getData();
            }
        }
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    }
}
</script>
