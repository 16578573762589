<template>
    <div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th>Action</vs-th>
                <vs-th>File Name</vs-th>
                <vs-th>File</vs-th>
                <vs-th>Imported By</vs-th>
                <vs-th>Start</vs-th>
                <vs-th>End</vs-th>
                <vs-th>Status</vs-th>
                <vs-th>Remark</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button
                                size="small"
                                color="warning"
                                icon-pack="feather"
                                icon="icon-eye"
                                title="Detail"
                                @click.stop="getDetail(tr.id)"
                                v-if="this.$store.getters['user/hasPermissions']('view')"
                            ></vs-button>
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.file_name }}</vs-td>
                    <vs-td>
                        <vs-button
                            v-if="tr.file_url && this.$store.getters['user/hasPermissions']('view')"
                            title="Download Imported File"
                            size="small"
                            color="success"
                            icon-pack="feather"
                            icon="icon-download"
                            @click.stop="download(tr)"
                        />
                        <span v-else>Unavailable</span>
                    </vs-td>
                    <vs-td>{{ tr.created_by }}</vs-td>
                    <vs-td>{{ utcToLocal(tr.start_process_at) }}</vs-td>
                    <vs-td>{{ utcToLocal(tr.end_process_at) }}</vs-td>
                    <vs-td>{{ tr.status }}</vs-td>
                    <vs-td>{{ tr.remark }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
import moment from "moment";

export default {
    components: {
        // ...
    },
    props: {
        draw: {
            type: Number,
        },
        warehouse: {
            type: Array,
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            ID: null,
            table: this.tableDefaultState(),
        }
    },
    mounted() {
        this.getData();
        this.$store.dispatch("user/getPermissions", "inter-storage");
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        setStartEnd() {
            let valStart = this.table.length * this.table.page - this.table.length + 1;
            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }

            let valEnd = this.table.length * this.table.page;
            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }
            if (this.table.totalSearch < this.table.total && this.table.search != "") {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        getData() {
            let warehouseIDs = [];
            this.warehouse.forEach(val => {
                warehouseIDs.push(val.id)
            });

            let param = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                warehouse_id: warehouseIDs,
                status: this.status,
            }

            this.$vs.loading();
            this.$http.post("api/wms/v1/inter-storage/log-import-table", param)
            .then((resp) => {
                if (resp.code == 200) {
                    this.table.total = resp.data.total_record;
                    this.table.totalPage = resp.data.total_page;
                    this.table.totalSearch = resp.data.total_record_search;
                    this.table.length = resp.data.total_record_per_page;
                    this.table.data = resp.data.records;
                    this.setStartEnd();
                    this.$vs.loading.close();
                } else {
                    this.$vs.loading.close();
                    console.log(resp.message);
                }
            })
            .catch((error) => {
                this.$vs.loading.close();
                console.log(error);
            });
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        getDetail(id) {
            this.$emit("logImportLine", id);
        },
        download(tr) {
            const link = document.createElement('a');
            link.href = tr.file_url;
            link.download = tr.file_name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
    },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => moment.utc(val).format("YYYY-MM-DD")
        },
    }
}
</script>